<template>
  <div id="app">
    <div id="header" v-if="isShowHeadAndFoot">
      <div class="headerContent">
        <img class="logo" src="@/assets/image/logo.png" />
        <div class="nav">
          <div
            class="nav-item"
            :class="{ active: activeClass == 'index' }"
            @click="jumpToIndex"
          >
            首页
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'brief' }"
            @click="jumpToBrief"
          >
            峰会简介
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'park' }"
            @click="jumpToPark"
          >
            园区
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'base' }"
            @click="jumpToBase"
          >
            基地
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'memberCenter' }"
            @click="jumpToMemberCenter"
          >
            会员中心
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'service' }"
            @click="jumpToService"
          >
            专业服务
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'activity' }"
            @click="jumpToActivity"
          >
            活动
          </div>
          <div
            class="nav-item"
            :class="{ active: activeClass == 'memberService' }"
            @click="jumpToMemberService"
          >
            会员服务
          </div>
          <div class="login-register" v-if="!$store.state.isUserName">
            <img src="./assets/image/login.png" alt="" class="people" />
            <div class="login-reg">
              <span class="to-login" @click="jumpToLogin">登陆</span>
              <span>|</span>
              <span class="to-login" @click="jumpToRegister">注册</span>
            </div>
          </div>
          <div class="login-register" v-if="$store.state.isUserName">
            <img src="./assets/image/login.png" alt="" class="people" />
            <div class="login-reg-name">
              {{ $store.state.userinfoname }}
            </div>
          </div>
          <div class="downLoad">
            <img class="dimg" alt="下载app" src="@/assets/image/downLoad.png" />
            <div class="qrWrap" style="width: 320px">
              <div style="margin-left: 7%;margin-top: 7%">
                <img style="margin-left: 12%;" src="@/assets/image/andriodQRcode.png" />
                <span style="display: inline-block">峰之汇Android端下载</span>
              </div>
              <div style="margin-top: 7%">
                <img style="margin-left: 6%;" src="@/assets/image/iosQRcode.png" />
                <span style="display: inline-block">峰之汇ios端下载</span>
<!--                <span>ios Android端下载</span>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[!isShowHeadAndFoot ? '' : 'app-content']">
      <router-view />
    </div>
    <foot v-if="isShowHeadAndFoot"></foot>
  </div>
</template>
<script>
import axios from 'axios';
import foot from '@/components/foot'
import baseUrl from './const/const';
import { setCookie, getCookie } from './const/utils';

export default {
  name: 'App',
  components: { foot },
  data () {
    return {
      activeClass: 'index',
      isShowHeadAndFoot: true,
      isUserName: false,
      userInfo: {}
    }
  },
  watch: {
    '$store.state.userInfo': {
      handler: function (newV, oldV) {
        console.log(newV, '4566')
        if (newV) {
          this.isUserName = true
          // let config = {
          //   headers: {
          //     'Content-Type': 'application/json;charset=UTF-8',
          //     'token': getCookie('token')
          //   }
          // }
          this.userInfo = newV
        } else {
          this.isUserName = false
        }
      },
      deep: true,
    },
  },
  created () {
    let curTime = new Date().getTime()
    let time = 7 * 24 * 60 * 60 * 1000
    if (localStorage.getItem('loginTime') && curTime - localStorage.getItem('loginTime') < time) {
      this.$store.state.isUserName = true
      this.$store.state.userinfoname = localStorage.getItem('userinfoname')
    } else {
      localStorage.clear()
      this.$store.state.isUserName = false
    }

  },
  mounted () {


  },
  methods: {
    jumpToIndex () {
      this.$router.push({
        path: '/'
      })
    },
    jumpToBrief () {
      this.$router.push({
        path: '/brief'
      })
    },
    jumpToPark () {
      this.$router.push({
        path: '/park'
      })
    },
    jumpToBase () {
      this.$router.push({
        path: '/base'
      })
    },
    jumpToMemberCenter () {
      this.$router.push({
        path: '/memberCenter'
      })
    },
    jumpToService () {
      this.$router.push({
        path: '/service'
      })
    },
    jumpToActivity () {
      this.$router.push({
        path: '/activity'
      })
    },
    jumpToMemberService () {
      this.$router.push({
        path: '/memberService'
      })
    },
    jumpToLogin () {
      this.$router.push({
        path: '/login'
      })
    },
    jumpToRegister () {
      this.$router.push({
        path: '/register'
      })
    },
  },
  watch: {
    $route (v) {
      this.activeClass = v.name;
      console.log(v)
      if (v.path == '/login' || v.path == '/register') {
        this.isShowHeadAndFoot = false
      } else {
        this.isShowHeadAndFoot = true
      }
    }
  }
}
</script>

<style lang="stylus">
@import './style/reset.css';
@import './style/global.stylus';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

::-webkit-scrollbar {
  display: none;
}

.app-content {
  padding-top: 64px;
}

#header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .headerContent {
    display: flex;
    max-width: 1100px;
    margin: 0 auto;
    height: 64px;
    position: relative;
    z-index: 999;

    .logo {
      width: 56px;
      height: 56px;
      transform: translateY(4px);
      float: left;
    }

    .nav {
      display: flex;
      float: right;
      height: 64px;
      transform: translateX(60px);
      position: relative;
      z-index: 999;

      .nav-item {
        height: 100%;
        padding: 0 18px 0;
        box-sizing: border-box;
        font-family: PingFangSC-Regular;
        font-size: 20px;
        color: #999999;
        text-align: center;
        line-height: 64px;
        cursor: pointer;
      }

      .active {
        background: #07365E;
        color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      }

      .login-register {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 20px;

        .people {
          width: 13px;
          height: 14px;
          margin-right: 10px;
        }

        .login-reg {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #07365E;
          letter-spacing: -0.09px;
          text-align: center;

          .to-login {
            height: 100%;
            line-height: 64px;
            padding-left: 3px;
            padding-right: 3px;
            cursor: pointer;
          }
        }

        .login-reg-name {
          height: 100%;
          width: 100px;
          line-height: 64px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .downLoad {
        height: 24px;
        padding: 0px;
        margin-left: 20px;
        margin-top: 20px;
        position: relative;
        z-index: 999;
        cursor: pointer;

        .dimg {
          width: 78px;
          height: 24px;
        }

        &:hover {
          .qrWrap {
            display: flex;
            //flex-direction: column;
            //justify-content: center;
            //align-items: center;
          }
        }

        .qrWrap {
          display: none;
          width: 160px;
          height: 170px;
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 999;
          background: rgba(0, 0, 0, 0.58);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 8px;

          // padding: 23px;
          img {
            width: 90px;
            height: 90px;
          }

          span {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #FFFFFF;
            white-space: nowrap;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>
