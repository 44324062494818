import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/iconfont/iconfont.css'
// import 'lib-flexible/flexible'
// import './const/rem'
Vue.config.productionTip = false
import Vuex from 'vuex'

Vue.use(Vuex)
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// router.beforeEach((to, from, next)=>{
//   document.title = '两岸峰会'
//   next()
// })