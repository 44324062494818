import axios from 'axios';
import baseUrl from '../const/const';



export const fetchMemberCenterList = (effectModule,name)=>{
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/classify/page`,{effectModule})
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data.records);
                } else {
                    rej();
                }
            })
    })
}
export const findIdByName = (effectModule,name)=>{
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/classify/page`,{effectModule})
            .then(({data})=>{
                if(data.code === "S00000"){
                    const id = data.data.records.find(_=>_.name === name).id;
                    res(id);
                } else {
                    rej();
                }
            })
    })
}
export const baseImageUrl = 'http://www.lianganfenghui.com/api/lafh-service/common/file/getImage?path=';

export const essayPage = ({classifyId})=>{ // 文章列表
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/essay/page`,{classifyId})
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data.records);
                } else {
                    rej();
                }
            })
    })
}


export const slideshowList = ()=>{ // 首页轮播图
    return new Promise((res,rej) => {
        axios.get(`${baseUrl}/front/slideshow/slideshowList?effectModule=6`)
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data);
                } else {
                    rej();
                }
            })
    })
}

export const pcIndexText = (classifyCode)=>{ // 首页轮播图
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/PCIndexText/all`, {classifyCode})
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data);
                } else {
                    rej();
                }
            })
    })
}

export const pcIndexInformation = (classifyCode)=>{ // 首页最新资讯
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/PCIndexInformation/page`,{})
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data.records);
                } else {
                    rej();
                }
            })
    })
}

export const pcIndexPartner = (classifyCode)=>{ // 合作企业
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/PCIndexPartner/all`)
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data);
                } else {
                    rej();
                }
            })
    })
}

export const essayApplySubmit = (data,config)=>{ // 提交表单
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/essayApply/submit`,data,config)
            .then(({data})=>{
                if(data.code === "S00000"){
                    console.log("data.data.records",data.data);
                    // const id = data.data.records.find(_=>_.name === name).id;
                    res(1);
                } else {
                    rej(0);
                }
            })
    })
}
export const getDetail = (id)=>{ // 获取文章详情
    return new Promise((res,rej) => {
        axios.get(`${baseUrl}/front/essay/${id}`)
            .then(({data})=>{
                if(data.code === "S00000"){
                    res(data.data);
                } else {
                    rej();
                }
            })
    })
}

export const getBrief = ()=>{ // 获取峰会简介
    return new Promise((res,rej) => {
        axios.post(`${baseUrl}/front/essay/page`, {effectModule: 2})
            .then(({data})=>{
                if(data.code === "S00000"){
                    if(data.data.records && data.data.records[0]) {
                        res(data.data.records[0]);
                    }
                } else {
                    rej();
                }
            })
    })
}


 //获取cookie、
 export function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
  return (arr[2]);
  else
  return null;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie (c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
};

//删除cookie
export function delCookie (name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
  document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

